<template>
    <div class="w-full flex flex-wrap">

		

        <div class="w-full md:w-3/5 flex flex-col">
			<div class="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-44 lg:px-44">

				<div>
					<h2 class="mt-6 text-left text-3xl font-extrabold text-gray-900">
						Reset Password
					</h2>
					<p class="mt-2 text-left text-sm text-gray-600">
						Silahkan masukan email anda pada kolom dibawah. Selanjutnya kami akan mengirimkan prosedur reset password melalui email anda!
					</p>
				</div>
				<form class="mt-8 space-y-7" @submit="submitLogin()">
					<custom-input id="email" type="email" label="Email" v-model="mainForm.email" :errorMessage="errorForm.email"/>
					<div>
						<button type="submit"
						@click="submitLogin()" 
						:disabled="isLoading"
						class="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-lg rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
							<span class="absolute left-0 inset-y-0 flex items-center pl-3">
								<svg class="h-5 w-5 text-blue-500 group-hover:text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									<path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
								</svg>
							</span>
							Kirim
						</button>
					</div>
				</form>
				<loading-modal :isShow="isLoading" />
			</div>
		</div>

		<div class="w-2/5">
            <div class="object-cover w-full h-screen hidden md:block bg-gray-300">
			</div>
        </div>

	</div>
</template>
<script>
	import CustomInput from '../components/CustomInput.vue';
	import LoadingModal from '../components/LoadingModal.vue';
	import {showSuccessNotif, showErrorNotif, extractValidationError, errorHandler} from '../helpers/Tools';

    export default {
		name: 'ResetPassword',
		components : {
			CustomInput, LoadingModal
		},
		data () {
            return {
				isLoading : false,
                mainForm: {
                    email    : null,
                },
                errorForm : {
                    email   : null,
                },
            }
        },
		methods : {
			async submitLogin () {
                this.isLoading = true;

                try {
                    this.errorForm = {
                        email   : null,
                        password: null,
					};

					let res            = await this.$store.dispatch("postResetPassword", this.mainForm);
					let result         = res.data;
					    this.isLoading = false;

					if(result.status == 'success') {
						showSuccessNotif(this, result.message);
					} else {
                        this.errorForm = extractValidationError(this.errorForm, result.data);
					}
					
                } catch (error) {
                    this.isLoading = false;
					errorHandler(this, error);
                }
            },
			
        }
    }
</script>
